import { useRef, useEffect } from 'react';
import styles from './page.module.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import chatMockup from '../../../assets/images/chats_home/chats_screen.png';
import friends_icon from '../../../assets/images/habit_home/people-outline.svg'
import add_icon from '../../../assets/images/habit_home/add-circle-outline.svg'
import graphic_icon from '../../../assets/images/habit_home/chart-outline.svg'

import MaskText from '../MaskTextEffect';

gsap.registerPlugin(ScrollTrigger);

export default function HeroChat() {
  const plane1 = useRef(null);
  const plane2 = useRef(null);
  let requestAnimationFrameId = null;
  let xForce = 0;
  let yForce = 0;
  const easing = 0.2;
  const speed = 0.005;

  const manageMouseMove = (e) => {
    const { movementX, movementY } = e;
    xForce += movementX * speed;
    yForce += movementY * speed;

    if (requestAnimationFrameId == null) {
      requestAnimationFrameId = requestAnimationFrame(animate);
    }
  };

  const lerp = (start, target, amount) => start * (1 - amount) + target * amount;

  const animate = () => {
    xForce = lerp(xForce, 0, easing);
    yForce = lerp(yForce, 0, easing);
    gsap.set(plane1.current, { x: `+=${xForce}`, y: `+=${yForce}` });
    gsap.set(plane2.current, { x: `+=${xForce * 0.5}`, y: `+=${yForce * 0.5}` });

    if (Math.abs(xForce) < 0.01) xForce = 0;
    if (Math.abs(yForce) < 0.01) yForce = 0;

    if (xForce !== 0 || yForce !== 0) {
      requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(requestAnimationFrameId);
      requestAnimationFrameId = null;
    }
  };

  const columnsRef = useRef([]);

  useEffect(() => {
    columnsRef.current.forEach((col, index) => {
      gsap.fromTo(
        col,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: index * 0.15,   // Escalonado por columna
          ease: 'power3.out',
          scrollTrigger: {
            trigger: col,
            start: 'top 85%',
          },
        }
      );
    });
  }, []);

  return (
<section 
  className={`${styles.habitsMain} bg-[#000] mt-10`} 
  onMouseMove={(e) => manageMouseMove(e)}
>  
  <div className="flex justify-center mt-28 mb-10" ref={(el) => (columnsRef.current[3] = el)}>
    <MaskText 
      text={['Juntos es mejor.']} 
      textSize='text-8xl/tight' 
      responsiveTextSize='text-6xl/tight' 
      textColor='text-white' 
    />
  </div>

  <div className="flex flex-col-reverse lg:flex-row justify-between max-w-screen-xl px-4 lg:pt-12 pt-5 mx-auto gap-8 lg:gap-0">
    {/* Columna de contenido (centrada y ordenada en mobile) */}
    <div className="lg:w-1/2 flex flex-col items-center lg:items-start text-center lg:text-left order-2 lg:order-1 gap-10 lg:gap-12 mx-auto max-lg:max-w-lg max-lg:pb-16">
      <div className="lg:mx-12 mx-6">
        <MaskText 
          textSize='text-2xl/relaxed' 
          text={['Únete a retos y', 'crece con tus amigos.']} 
          responsiveTextSize='text-3xl/tight' 
          textColor='text-white' 
        />

        {/* Primer elemento */}
        <div className="flex flex-col items-center lg:items-start my-6" ref={(el) => (columnsRef.current[0] = el)}>
          <h3 className="text-xl text-white font-semibold mb-2">Unidos por un mismo objetivo</h3>
          <p className="text-gray-400">
            Los objetivos grupales sirven para conocer gente nueva o cumplir algo con amigos.
          </p>
        </div>

        {/* Segundo elemento */}
        <div className="flex flex-col items-center lg:items-start my-6" ref={(el) => (columnsRef.current[1] = el)}>
          <h3 className="text-xl text-white font-semibold mb-2">Que la conversación no acabe</h3>
          <p className="text-gray-400">
            Todos los integrantes del reto tienen acceso a un chat grupal.
          </p>
        </div>

        {/* Tercer elemento */}
        <div className="flex flex-col items-center lg:items-start my-6" ref={(el) => (columnsRef.current[2] = el)}>
          <h3 className="text-xl text-white font-semibold mb-2">Infinitos retos</h3>
          <p className="text-gray-400">
            Podrás unirte al reto de otra persona o crear uno tú mismo y que los demás se unan.
          </p>
        </div>

        {/* Botón */}
        <div className="flex justify-center mt-10" ref={(el) => (columnsRef.current[3] = el)}>
          <a
            href="/download"
            className="cursor-pointer bg-white text-black text-lg border-[1.5px] w-[200px] text-center border-white font-medium py-3 px-6 rounded-[7px] no-underline hover:no-underline hover:bg-black hover:text-white transition-colors duration-300"
          >
            Únete a Scala
          </a>
        </div>
      </div>
    </div>

    {/* Columna de imagen (abajo en mobile, derecha en desktop) */}
    <div className="flex flex-1 items-end justify-center lg:justify-end lg:w-1/2 order-1 lg:order-2">
      <img 
        src={chatMockup} 
        alt="mockup" 
        className="max-w-md w-full mx-auto lg:mx-8" 
      />
    </div>
  </div>
</section>





  );
}
