import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import iosDownloadImage from '../../../assets/images/logo-apple.svg';
import androidDownloadImage from '../../../assets/images/logo-playstore.svg';
import MaskText from '../MaskTextEffect';

gsap.registerPlugin(ScrollTrigger);

const HeroDownload = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;

    gsap.from(section.querySelectorAll(".fade-item"), {
      opacity: 0,
      y: 50,
      stagger: 0.2,
      duration: 1.3,
      delay: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 85%", // Inicia la animación cuando el 80% del contenedor entra en el viewport
        end: "bottom 15%", // Termina cuando el 20% sale del viewport
      },
    });
  }, []);

  return (
    <div
      ref={sectionRef}
      className="flex flex-col items-center justify-center text-center py-28 bg-[#F4F5F7]"
    >
      <MaskText
        text={['Descarga la app para iOS o Android']}
        textSize="text-4xl/tight"
        responsiveTextSize="text-5xl/tight"
      />
      <div className="flex flex-col sm:flex-row gap-6 mt-12 fade-item">
        <a
          target="_blank"
          className="flex flex-row items-center justify-center cursor-pointer h-fit bg-black text-white text-lg border-[1.5px] w-full sm:w-fit text-center border-black font-medium py-3 px-5 rounded-[7px] no-underline hover:no-underline hover:scale-105 transition-transform duration-200"
          rel="noreferrer"
          href="https://apple.co/4bugtF0"
        >
          <img
            src={iosDownloadImage}
            alt="Descarga en App Store"
            className="w-6 md:w-6 me-2"
          />
          Descarga en App Store
        </a>
        <a
          target="_blank"
          className="flex flex-row items-center justify-center cursor-pointer h-fit bg-black text-white text-lg border-[1.5px] w-full sm:w-fit text-center border-black font-medium py-3 px-5 rounded-[7px] no-underline hover:no-underline hover:scale-105 transition-transform duration-200"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.scala.app"
        >
          <img
            src={androidDownloadImage}
            alt="Descarga para Google Play"
            className="w-6 md:w-6 me-2"
          />
          Descarga en Google Play
        </a>
      </div>
    </div>
  );
};

export default HeroDownload;
