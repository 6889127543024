// TextRotator.js
import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import styles from './Hero/page.module.scss'

const TextRotator = ({ staticText, rotatingWords, backgroundColors }) => {
  const wordRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const wordAnimation = () => {
      // Animar palabra que desaparece
      gsap.fromTo(
        wordRef.current,
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: -20,
          duration: 0.5,
          onComplete: () => {
            // Cambiar palabra después de animación de salida
            setCurrentIndex((prevIndex) => (prevIndex + 1) % rotatingWords.length);

            // Animación de entrada de la nueva palabra
            gsap.fromTo(
              wordRef.current,
              { opacity: 0, y: 20 },
              { opacity: 1, y: 0, duration: 0.5 }
            );
          },
        }
      );
    };

    // Llamar animación cada cierto tiempo
    const interval = setInterval(wordAnimation, 2000);
    return () => clearInterval(interval);
  }, [rotatingWords]);

  return (
    <span className='lg:text-4xl md:text-3xl text-base font-medium text-black'>
      {staticText}{' '}
      <span
        ref={wordRef}
        className={styles.rotatingWord}
        style={{
          backgroundColor: backgroundColors[currentIndex],
        }}
      >
        {rotatingWords[currentIndex]}
      </span>
    </span>
  );
};

export default TextRotator;
