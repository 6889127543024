import styles from './page.module.scss';
import { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import logo_sc from '../../logo.svg';
import TextRotator from '../TextRotator';
import {
    floating1, 
    floating10, 
    floating2, 
    floating3, 
    floating4, 
    floating5, 
    floating6, 
    floating7, 
    floating8,
    floating9
} from './data';

export default function HeroFloating() {
  const plane1 = useRef(null);
  const plane2 = useRef(null);
  const plane3 = useRef(null);
  let requestAnimationFrameId = null;
  let xForce = 0;
  let yForce = 0;
  const easing = 0.2;
  const speed = 0.005;

  const manageMouseMove = (e) => {
    const { movementX, movementY } = e;
    xForce += movementX * speed;
    yForce += movementY * speed;

    if (requestAnimationFrameId == null) {
      requestAnimationFrameId = requestAnimationFrame(animate);
    }
  };

  const lerp = (start, target, amount) => start * (1 - amount) + target * amount;

  const animate = () => {
    xForce = lerp(xForce, 0, easing);
    yForce = lerp(yForce, 0, easing);
    gsap.set(plane1.current, { x: `+=${xForce}`, y: `+=${yForce}` });
    gsap.set(plane2.current, { x: `+=${xForce * 0.5}`, y: `+=${yForce * 0.5}` });
    gsap.set(plane3.current, { x: `+=${xForce * 0.25}`, y: `+=${yForce * 0.25}` });

    if (Math.abs(xForce) < 0.01) xForce = 0;
    if (Math.abs(yForce) < 0.01) yForce = 0;

    if (xForce !== 0 || yForce !== 0) {
      requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(requestAnimationFrameId);
      requestAnimationFrameId = null;
    }
  };

  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo("#hero-logo-sc", { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.2 });

    // Animación de las imágenes en plane1
    Array.from(plane1.current.children).forEach((img, i) => {
      const { left, top } = img.style;
      gsap.fromTo(
        img,
        { opacity: 0, },
        { opacity: 1, left, top, duration: 1, delay: i * 0.3 }
      );
    });

    // Animación de las imágenes en plane2
    Array.from(plane2.current.children).forEach((img, i) => {
      const { left, top } = img.style;
      gsap.fromTo(
        img,
        { opacity: 0, },
        { opacity: 1, left, top, duration: 1, delay: i * 0.3 + 0.5 }
      );
    });

    // Animación de las imágenes en plane3
    Array.from(plane3.current.children).forEach((img, i) => {
      const { left, top } = img.style;
      gsap.fromTo(
        img,
        { opacity: 0, },
        { opacity: 1, left, top, duration: 1, delay: i * 0.3 + 1.0 }
      );
    });

    // Configura ScrollTrigger
    ScrollTrigger.create({
        trigger: "#heroFloating",
        start: "top center",    // Ajusta el punto de inicio del trigger
        end: "bottom center",
        scrub: true,
        toggleActions: 'play reverse play reverse',
        onEnter: () => {
          gsap.to(".parallaxContent", {
            backgroundColor: "#F4F5F7",
            duration: 0.3,
          });
        },
        onEnterBack: () => {

            Array.from(plane1.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 1,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3,
                });
              });
              Array.from(plane2.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 1,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3 + 0.3,
                });
              });
              Array.from(plane3.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 1,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3 + 0.5,
                });
              });

          gsap.to(".parallaxContent", {
            backgroundColor: "#F4F5F7",
            duration: 0.3,
          });
        },
        onLeaveBack: () => {

            Array.from(plane1.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 0,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3,
                });
              });
              Array.from(plane2.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 0,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3 + 0.3,
                });
              });
              Array.from(plane3.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 0,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3 + 0.5,
                });
              });

          gsap.to(".parallaxContent", {
            backgroundColor: "#FFF",
            duration: 0.3,
          });
        },
        onLeave: () => {

            Array.from(plane1.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 0,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3,
                });
              });
              Array.from(plane2.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 0,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3 + 0.3,
                });
              });
              Array.from(plane3.current.children).forEach((img, i) => {
                gsap.to(img, {
                  opacity: 0,
                  duration: 0.3,
                  ease: "power4.out",
                  delay: i * 0.3 + 0.5,
                });
              });

          gsap.to(".parallaxContent", {
            backgroundColor: "#FFF",
            duration: 0.3,
          });
        }
      });
  }, []);

  return (
    <main id="heroFloating" onMouseMove={(e) => manageMouseMove(e)} className={styles.main}>
      <div ref={plane1} id='plane1' className={styles.plane}>
        <img src={floating1} alt='image1' width={300} />
        <img src={floating2} alt='image2' width={380} />
        <img src={floating7} alt='image3' width={265} />
      </div>
      <div ref={plane2} id='plane2' className={styles.plane}>
        <img src={floating4} alt='image4' width={280} />
        <img src={floating6} alt='image5' width={280} />
        <img src={floating8} alt='image6' width={285} />
        <img src={floating9} alt='image7' width={215} />
      </div>
      <div ref={plane3} id='plane3' className={styles.plane}>
        <img src={floating3} alt='image8' width={250} />
        <img src={floating5} alt='image9' width={240} />
        <img src={floating10} alt='image10' width={270} />
      </div>
      <img id="hero-logo-sc" src={logo_sc} className={styles.logoTopElement} alt="" />

      <div className={styles.title}>
        <h1 className='lg:text-5xl md:text-3xl text-xl font-medium mb-1 text-black'>Motívate a cumplir tus metas</h1>
        <TextRotator
          staticText="Viendo a tus amigos cumplir sus"
          rotatingWords={['objetivos', 'retos', 'hábitos']}
          backgroundColors={['#FFD950', '#F4A5C1', '#7BF4AC']}
        />
  <div className="flex justify-center lg:mt-16 mt-6">
  <a
      href="/download"
      className="cursor-pointer bg-black text-white lg:text-lg text-sm border-[1.5px] w-fit text-center border-black font-medium lg:py-3 lg:px-6 py-2 px-4 rounded-[7px] no-underline hover:no-underline hover:bg-white hover:text-black transition-colors duration-300"
    >
      Descarga la app
    </a>
  </div>
      </div>
    </main>
  );
}
