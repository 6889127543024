import { useRef, useEffect } from 'react';
import styles from './page.module.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import habitMockup from '../../../assets/images/Mockup_Habit.png';
import tenis_habit from '../../../assets/images/habit_home/tenis_habit.png'
import fruta_habit from '../../../assets/images/habit_home/fruta_habit.png'
import friends_icon from '../../../assets/images/habit_home/people-outline.svg'
import add_icon from '../../../assets/images/habit_home/add-circle-outline.svg'
import graphic_icon from '../../../assets/images/habit_home/chart-outline.svg'

import MaskText from '../MaskTextEffect';

gsap.registerPlugin(ScrollTrigger);

export default function HeroHabit() {
  const plane1 = useRef(null);
  const plane2 = useRef(null);
  let requestAnimationFrameId = null;
  let xForce = 0;
  let yForce = 0;
  const easing = 0.2;
  const speed = 0.005;

  const manageMouseMove = (e) => {
    const { movementX, movementY } = e;
    xForce += movementX * speed;
    yForce += movementY * speed;

    if (requestAnimationFrameId == null) {
      requestAnimationFrameId = requestAnimationFrame(animate);
    }
  };

  const lerp = (start, target, amount) => start * (1 - amount) + target * amount;

  const animate = () => {
    xForce = lerp(xForce, 0, easing);
    yForce = lerp(yForce, 0, easing);
    gsap.set(plane1.current, { x: `+=${xForce}`, y: `+=${yForce}` });
    gsap.set(plane2.current, { x: `+=${xForce * 0.5}`, y: `+=${yForce * 0.5}` });

    if (Math.abs(xForce) < 0.01) xForce = 0;
    if (Math.abs(yForce) < 0.01) yForce = 0;

    if (xForce !== 0 || yForce !== 0) {
      requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(requestAnimationFrameId);
      requestAnimationFrameId = null;
    }
  };

  const columnsRef = useRef([]);

  useEffect(() => {
    columnsRef.current.forEach((col, index) => {
      gsap.fromTo(
        col,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: index * 0.15,   // Escalonado por columna
          ease: 'power3.out',
          scrollTrigger: {
            trigger: col,
            start: 'top 85%',
          },
        }
      );
    });
  }, []);

  return (
<section 
  className={`${styles.habitsMain} bg-[#F4F5F7] mt-10 max-lg:pt-10`} 
  onMouseMove={(e) => manageMouseMove(e)}
>
<div ref={plane1} id='plane1' className={`${styles.habitsPlane} max-lg:hidden`}>
        <img src={tenis_habit} alt='habito de tenis' width={300} />
      </div>
      <div ref={plane2} id='plane2' className={`${styles.habitsPlane} max-lg:hidden`}>
        <img src={fruta_habit} alt='habito de comer fruta' width={350} />
      </div>
  <div className="flex flex-col-reverse lg:flex-row max-lg:items-center justify-between max-w-screen-xl px-4 pt-16 mx-auto gap-8 lg:gap-0 lg:pt-24">
    
    {/* Columna de imagen, que en escritorio estará en el lado izquierdo */}
    <div className="flex flex-1 items-end justify-center lg:justify-start lg:w-1/2">

    <img src={habitMockup} alt="mockup" className="mx-20 max-w-md w-full mx-auto lg:mx-8" />
    </div>

    {/* Columna de contenido, que en mobile estará primero */}
    <div className="lg:w-1/2 text-center flex max-lg:max-w-lg max-lg:px-5 max-lg:items-center flex-1 items-start lg:text-left">
      <div className="lg:mx-12 mx-0 mb-16">
        <MaskText text={['Pequeños pasos,', 'grandes cambios.']} />
    {/* Primer elemento */}
    <div className="flex flex-col items-center lg:items-start my-10" ref={(el) => (columnsRef.current[0] = el)}>
      <img src={friends_icon} alt="Icono 1" className="w-10 h-10 mb-4" />
      <h3 className="text-xl font-semibold mb-2">Comparte tus hábitos</h3>
      <p className="text-gray-400 text-center lg:text-left">
        Tus amigos podrán ver tus rutinas y el trabajo de tu día.
      </p>
    </div>
    
    {/* Segundo elemento */}
    <div className="flex flex-col items-center lg:items-start my-10" ref={(el) => (columnsRef.current[1] = el)}>
      <img src={add_icon} alt="Icono 2" className="w-10 h-10 mb-4" />
      <h3 className="text-xl font-semibold mb-2">Copia otras rutinas</h3>
      <p className="text-gray-400 text-center lg:text-left">
        Añade las rutinas de tus amigos en tus habitos pulsando un solo botón
      </p>
    </div>
    
    {/* Tercer elemento */}
    <div className="flex flex-col items-center lg:items-start my-10" ref={(el) => (columnsRef.current[2] = el)}>
      <img src={graphic_icon} alt="Icono 3" className="w-10 h-10 mb-4" />
      <h3 className="text-xl font-semibold mb-2">Visualiza tu progreso</h3>
      <p className="text-gray-400 text-center lg:text-left">
        Podrás ver todos tus hábitos y objetivos completados desde tu perfil
      </p>
    </div>

    <div className="flex justify-center mt-14" ref={(el) => (columnsRef.current[3] = el)}>
  <a
      href="/download"
      className="cursor-pointer bg-black text-white text-lg border-[1.5px] w-[200px] text-center border-black font-medium py-3 px-6 rounded-[7px] no-underline hover:no-underline hover:bg-white hover:text-black transition-colors duration-300"
    >
      Descarga la app
    </a>
  </div>
      </div>
    </div>
    

    
  </div>

  
</section>


  );
}
