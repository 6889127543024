import React, { useEffect, useRef } from 'react';
import styles from './Hero/page.module.scss';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const MaskText = ({ text, textColor = "text-[#111]", textSize = "text-4xl/relaxed", responsiveTextSize = "text-5xl/tight" }) => {
  const body = useRef(null);
  const phrasesRef = useRef([]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        phrasesRef.current,
        { y: "100%", opacity: 0 },
        {
          y: "0%",
          opacity: 1,
          duration: 1,
          delay: 0.4,
          ease: [0.33, 1, 0.68, 1],
          stagger: 0.075,
          scrollTrigger: {
            trigger: body.current,
            start: "top bottom",
            scrub: false,
          },
        }
      );
    });

    return () => ctx.revert();
  }, []);

  return (
    <div ref={body} className={styles.maskTextContainer}>
      {text.map((phrase, index) => (
        <div key={index} className={styles.lineMask}>
          <p
            className={`text-balance text-center lg:${textSize} font-semibold tracking-tight ${textColor} ${responsiveTextSize}`}
            ref={(el) => (phrasesRef.current[index] = el)}
          >
            {phrase}
          </p>
        </div>
      ))}
    </div>
  );
};

export default MaskText;
