import React, {useEffect} from "react";
import HeroHeader from "./HeroHeader";

import './style.css';
import HeroCollage from "./ImageComponent";
import FeatureSlides from "./FeatureSlides";
import Footer from '../../components/Footer';
import HeroDownload from "./HeroDownload";
import WaitlistButton from "../WaitlistButtonTop"
import WaitlistButtonMobile from "../WaitlistButtonTopMobile"
import HeroMission from "./HeroMission";
import HeroFAQ from "./HeroFAQ";
import HeroFloating from "./HeroFloating";
import HeroBento from "./HeroBento";
import HeroHabit from "./HeroHabit";
import HeroPost from "./HeroPost";
import HeroChat from "./HeroChat";
import HeroBullet from "./HeroBullet";
import FAQ from "./HeroFAQs";
import SmartAppBanner from "../SmartAppBanner";


export default function Hero() {
  const [bannerHeight, setBannerHeight] = React.useState(0);

  useEffect(() => {

    const updateBody = () => {
      const body = document.body;

      body.classList.add("body-background");

      const banner = document.querySelector(".smart-app-banner");
      if (banner) setBannerHeight(banner.offsetHeight);
      const logo = document.querySelector("#hero-logo-sc");

      if (logo) {
        logo.style.top = `${50 + bannerHeight}px`; // 5px más la altura del banner
      }

      const handleResize = () => {
        if (banner) {
          const newBannerHeight = banner.offsetHeight;
          setBannerHeight(newBannerHeight);
          if (logo) {
            logo.style.top = `${50 + newBannerHeight}px`;
          }
        }
      };
  
      window.addEventListener("resize", handleResize);
  
      // Cleanup
      return () => window.removeEventListener("resize", handleResize);
    
    };

    updateBody();
  }, [bannerHeight]);

  return (
    <div>
      {/* <WaitlistButton/>
      <WaitlistButtonMobile/> */}
      <SmartAppBanner/>
    <main className='parallaxContent' style={{ paddingTop: `${bannerHeight}px` }}>
      <div className="hero-container">
        <HeroFloating/>
        <HeroBento/>
        <HeroHabit/>
        <HeroPost/>
        <HeroChat/>
        <HeroBullet/>
        <HeroDownload/>
        <FAQ/>
        {/* <FeatureSlides />

        <HeroMission/>
        <HeroFAQ/> */}
        </div >
    </main>
      
    <Footer />
    </div >
    
  );
}