import { useEffect, useRef } from 'react';
import styles from './page.module.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import obj_icon from '../../../assets/images/post_home/checkmark-outline.svg';
import reto_icon from '../../../assets/images/post_home/flash-outline.svg';
import hab_icon from '../../../assets/images/post_home/infinite-outline.svg';
import bul_icon from '../../../assets/images/post_home/trail-sign-outline.svg';

import MaskText from '../MaskTextEffect';

gsap.registerPlugin(ScrollTrigger);

export default function HeroPost() {
  const columnsRef = useRef([]);

  useEffect(() => {
    columnsRef.current.forEach((col, index) => {
      gsap.fromTo(
        col,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: index * 0.15,   // Escalonado por columna
          ease: 'power3.out',
          scrollTrigger: {
            trigger: col,
            start: 'top 85%',
          },
        }
      );
    });
  }, []);
  

  return (
    <section className="bg-white lg:py-40 py-28 px-4">
      <MaskText text={['Todo lo que necesitas para ayudarte a crecer']} />

      <div className="flex flex-col lg:flex-row justify-between max-w-screen-lg max-lg:max-w-lg max-lg:px-5 xl:max-w-screen-xl mx-auto gap-8 mt-20">
        
        {/* Columna de Objetivo */}
        <div
          className="flex flex-col items-center lg:items-start items-center text-center lg:w-1/4"
          ref={(el) => (columnsRef.current[0] = el)}
        >
          <img src={obj_icon} alt="Objetivo" className="w-12 h-12 mb-7" />
          <h3 className="text-2xl font-semibold mb-2">Define tus Objetivos</h3>
          <p className="text-gray-400 font-medium text-center lg:text-left">
            Sigue y comparte tus metas individuales, enfocándote en tus sueños y logros personales.
          </p>
        </div>

        {/* Columna de Reto */}
        <div
          className="flex flex-col items-center lg:items-start items-center text-center lg:w-1/4"
          ref={(el) => (columnsRef.current[1] = el)}
        >
          <img src={reto_icon} alt="Reto" className="w-12 h-12 mb-7" />
          <h3 className="text-2xl font-semibold mb-2">Únete a Retos</h3>
          <p className="text-gray-400 font-medium text-center lg:text-left">
            Únete a retos en grupo y alcanza metas compartidas junto a tus amigos.
          </p>
        </div>

        {/* Columna de Hábito */}
        <div
          className="flex flex-col items-center lg:items-start items-center text-center lg:w-1/4"
          ref={(el) => (columnsRef.current[2] = el)}
        >
          <img src={hab_icon} alt="Hábito" className="w-12 h-12 mb-7" />
          <h3 className="text-2xl font-semibold mb-2">Gestiona tus Hábitos</h3>
          <p className="text-gray-400 font-medium text-center lg:text-left">
            Crea y gestiona rutinas diarias que se adapten a tus necesidades y te ayuden a mejorar.
          </p>
        </div>

        {/* Columna de Bullet Journal */}
        <div
          className="flex flex-col items-center lg:items-start items-center text-center lg:w-1/4"
          ref={(el) => (columnsRef.current[3] = el)}
        >
          <img src={bul_icon} alt="Bullet Journal" className="w-12 h-12 mb-7" />
          <h3 className="text-2xl font-semibold mb-2">Bullet Journal, tu espacio</h3>
          <p className="text-gray-400 font-medium text-center lg:text-left">
            Lleva un registro creativo y personal de tu progreso, anotando cada paso que das.
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-24" ref={(el) => (columnsRef.current[4] = el)}>
        <a
          href="/download"
          className="cursor-pointer bg-black text-white text-lg border-[1.5px] w-[200px] text-center border-black font-medium py-3 px-6 rounded-[7px] no-underline hover:no-underline hover:bg-white hover:text-black transition-colors duration-300"
        >
          Descarga la app
        </a>
      </div>
    </section>
  );
}
