import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MaskText from "../MaskTextEffect";

gsap.registerPlugin(ScrollTrigger);

const FAQ = () => {
  const faqs = [
    { 
      question: "¿Qué es Scala?", 
      answer: `<p class="mb-4">
      Scala es una plataforma que te ayuda a alcanzar tus objetivos y metas, motivándote a través de los logros de otros y conectándote con personas con intereses similares.
    </p>
    
    <p class="mb-4">
      En Scala, en la pestaña 
      <ion-icon name="add-outline" ></ion-icon> 
      <span class="font-bold">Crear</span>, puedes añadir:
    </p>
    
    <p class="mb-4">
      <span class="gradient-text-goal">
        <ion-icon class="gradient-icon-goal" name="checkmark-outline"></ion-icon> Objetivos
      </span>: Metas concretas que deseas alcanzar. Una vez cumplas un objetivo, puedes publicarlo con una foto que certifique tu logro, compartiéndolo con tus amigos para celebrar tu éxito.
    </p>
    
    <p class="mb-4">
      <span class="gradient-text-habit">
        <ion-icon class="gradient-icon-habit" name="infinite-outline"></ion-icon> Hábitos
      </span>: Actividades repetitivas que quieres incorporar a tu rutina diaria o periódica. Scala te permite registrar tus hábitos, hacer seguimiento de tu progreso y resetear las repeticiones al finalizar cada día.
    </p>
    
    <p class="mb-4">
      <span class="gradient-text-achievement">
        <ion-icon class="gradient-icon-achievement" name="flash-outline"></ion-icon> Retos
      </span>: Objetivos grupales en los que los demás usuarios pueden unirse. Una vez creado un reto, estará disponible en la pestaña 
      <ion-icon name="chatbubbles-outline" ></ion-icon> 
      <span class="font-bold">Chats</span>, donde podrás acceder a sus detalles y participar en el chat exclusivo del reto para comunicarte con los otros participantes.
    </p>
    
    <p class="mb-4">
      En la pestaña 
      <ion-icon name="search-outline" ></ion-icon> 
      <span class="font-bold">Explora</span>, encontrarás perfiles inspiradores, ideas de nuevos 
      <span class="gradient-text-goal">
        <ion-icon class="gradient-icon-goal" name="checkmark-outline"></ion-icon> objetivos
      </span>, 
      <span class="gradient-text-habit">
        <ion-icon class="gradient-icon-habit" name="infinite-outline"></ion-icon> hábitos
      </span> y 
      <span class="gradient-text-achievement">
        <ion-icon class="gradient-icon-achievement" name="flash-outline"></ion-icon> retos
      </span>, además de logros de otros usuarios con los que motivarte.
    </p>
    
    <p class="mb-4">
      Scala también incluye un <span class="font-bold">Bullet Journal</span>, donde puedes registrar tu estado de ánimo, planificar tus días y reflexionar sobre tu progreso. Este espacio personal te ayuda a mantenerte enfocado en tus metas y 
      hábitos mientras evalúas tu crecimiento.
    </p>
    
    <p class="mb-4">
      Finalmente, puedes personalizar tu perfil editando detalles como tu nombre, nombre de usuario o foto de perfil. También tienes la posibilidad de bloquear o reportar a otros usuarios, así como ocultar o reportar contenido inapropiado.
    </p>
    `
    },
    {
      question: "¿Qué puedo crear en Scala?",
      answer: `<p class="mb-4">En Scala podrás crear <span class="gradient-text-goal"><ion-icon class="gradient-icon-goal" name="checkmark-outline"></ion-icon>objetivos</span>, que son metas que nos proponemos nosotros mismos individualmente y que publicaremos una vez lo hayamos cumplido.</p>
               <p class="mb-4">Algunos ejemplos de <span class="gradient-text-goal"><ion-icon class="gradient-icon-goal" name="checkmark-outline"></ion-icon>objetivos</span> podrían ser: "Viajar a otro país", "Aprender un nuevo idioma", "Alimentarme mejor" ...</p>
               <p class="mb-4">También podrás registrar <span class="gradient-text-habit"><ion-icon class="gradient-icon-habit" name="infinite-outline"></ion-icon>hábitos</span>, que son actividades repetitivas que deseas medir y mejorar en tu rutina diaria o periódica. Scala te permite hacer un seguimiento numérico de tus progresos y reiniciarlos al finalizar el día.</p>
               <p class="mb-4">Algunos ejemplos de <span class="gradient-text-habit"><ion-icon class="gradient-icon-habit" name="infinite-outline"></ion-icon>hábitos</span> podrían ser: "Caminar 3 veces al día", "Leer 2 veces diarias", "Comer 5 piezas de fruta" ...</p>
               <p class="mb-4">Y también podrás crear <span class="gradient-text-achievement"><ion-icon class="gradient-icon-achievement" name="flash-outline"></ion-icon>retos</span>, que son objetivos grupales, en los que los demás usuarios podrán unirse y ayudarnos a cumplirlos.</p> 
               <p class="mb-4">Algunos ejemplos de <span class="gradient-text-achievement"><ion-icon class="gradient-icon-achievement" name="flash-outline"></ion-icon>retos</span> podrían ser: "Hacer un voluntariado", "Recoger basura de la playa", "Torneo deportivo" ...</p>`
    },    
    { 
      question: "Crear un objetivo", 
      answer: `<p class="mb-4">1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear'.</p>
              <p class="mb-4">2. Pulsa en el botón '<ion-icon name="add-outline"></ion-icon>Crear nuevo'.</p>
                <p class="mb-4">3. Elige una categoría que se ajuste al objetivo que quieras cumplir y pulsa sobre ella.</p>
                <p class="mb-4">4. En el desplegable 'Crear', selecciona <span class="gradient-text-goal"><ion-icon class="gradient-icon-goal" name="checkmark-outline"></ion-icon>Objetivo</span>.</p>
                <p class="mb-4">5. Rellena el formulario añadiendo el título de tu objetivo y la fecha límite.</p>
                <p class="mb-4">6. Pulsa sobre 'Crear objetivo'</p>
                <br/> <p class="mb-4">Tu objetivo se ha creado y se encuentra en la pantalla <ion-icon name="add-outline"></ion-icon>Crear, una vez hayas cumplido tu objetivo, podrás publicarlo.</p>`
    },
    { 
      question: "Publicar un objetivo", 
      answer: `<p class="mb-4">1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear'.</p>
              <p class="mb-4">2. Pulsa en el botón '<ion-icon name="checkmark-outline"></ion-icon>'  del objetivo que hayas cumplido.</p>
                <p class="mb-4">3. En el desplegable pulsa sobre 'Publicar'</span>.</p>
                <p class="mb-4">4. Añade una imagen que certifique que has cumplido el objetivo.</p>
                <p class="mb-4">5. Pulsa sobre 'Publicar'</p>
                <br/> <p class="mb-4">Tu objetivo se ha publicado y tus seguidores podrán verlo</p>`
    },
    { 
      question: "Crear un hábito", 
      answer: `<p class="mb-4">1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear'.</p>
              <p class="mb-4">2. Pulsa en el botón '<ion-icon name="add-outline"></ion-icon>Crear nuevo'.</p>
                <p class="mb-4">3. Elige una categoría que se ajuste al hábito que quieras cumplir y pulsa sobre ella.</p>
                <p class="mb-4">4. En el desplegable 'Crear', selecciona <span class="gradient-text-habit"><ion-icon class="gradient-icon-habit" name="infinite-outline"></ion-icon>Hábito</span>.</p>
                <p class="mb-4">5. Rellena el formulario añadiendo el título de tu hábito, las repeticiones, los días que quieres realizarlo y de forma opcional una fecha de finalización.</p>
                <p class="mb-4">6. Pulsa sobre 'Crear hábito'</p>
                <br/> <p class="mb-4">Tu hábito se ha creado y se encuentra en la pantalla <ion-icon name="add-outline"></ion-icon>Crear, en el apartado 'Para hoy' los días que hayas marcado en el formulario de creación. Cada vez hayas cumplido tu hábito, podrás publicarlo.</p>`
    },
    { 
      question: "Publicar un hábito", 
      answer: `<p class="mb-4">1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear', en el apartado 'Para hoy'.</p>
              <p class="mb-4">2. Pulsa en el botón '<ion-icon name="add-outline"></ion-icon>' hasta finalizar las repeticiones de tu hábito y pulsa finalmente en el botón '<ion-icon name="checkmark-outline"></ion-icon>' para completar el hábito.</p>
                <p class="mb-4">3. En el desplegable pulsa sobre 'Publicar'</span>.</p>
                <p class="mb-4">4. Añade una imagen que certifique que has cumplido el hábito.</p>
                <p class="mb-4">5. Pulsa sobre 'Publicar'</p>
                <br/> <p class="mb-4">Tu hábito se ha publicado y tus seguidores podrán verlo</p>`
    },
    { 
      question: "Crear un reto", 
      answer: `<p class="mb-4">1. Ve a la pestaña '<ion-icon name="add-outline"></ion-icon>Crear'.</p>
              <p class="mb-4">2. Pulsa en el botón '<ion-icon name="add-outline"></ion-icon>Crear nuevo'.</p>
                <p class="mb-4">3. Elige una categoría que se ajuste al reto que quieras crear y pulsa sobre ella.</p>
                <p class="mb-4">4. En el desplegable 'Crear', selecciona <span class="gradient-text-achievement"><ion-icon class="gradient-icon-achievement" name="flash-outline"></ion-icon>Reto</span>.</p>
                <p class="mb-4">5. Rellena el formulario añadiendo el título de tu reto, la fecha y hora en que se realizará, la ubicación (es opcional) y añade una descripción detallada de lo que será tu reto para que los demás participantes puedan estar informados y se unan.</p>
                <p class="mb-4">6. Pulsa sobre 'Añadir imagen'</p>
                <p class="mb-4">7. Añade una imagen sobre tu reto</p>
                <p class="mb-4">8. Pulsa sobre 'Publicar'</p>
                <br/> <p class="mb-4">Tu reto se ha creado y se encuentra en la pantalla de '<ion-icon name="home-outline"></ion-icon>Inicio' y en la pantalla de '<ion-icon class="gradient-icon-achievement" name="flash-outline"></ion-icon>Chats', los demás usuarios podrán unirse a tu reto y hablar por el chat.</p>`
    },
    { 
      question: "Bloquear o desbloquear un perfil en Scala", 
      answer: `<p class="mb-4">Si bloqueas a alguien, no podrá ver nada de lo que publiques en Scala. La persona podrá ver que lo has bloqueado si entra en tu perfil.</p>
              <p class="mb-4">Para bloquear a alguien sigue los siguientes pasos:</p>
              <p class="mb-4">1. Ve al perfil de la persona que quieras bloquear.</p>
              <p class="mb-4">2. Pulsa en '<ion-icon name="ellipsis-vertical"></ion-icon>'.</p>
                <p class="mb-4">3. En el desplegable pulsa sobre '<ion-icon name="ban-outline"></ion-icon>Bloquear usuario'</span>.</p>
                <br/> <p class="mb-4">Si desbloqueas a alguien, volverá ver lo que publiques en Scala.</p>
                <p class="mb-4">Para desbloquear a alguien sigue los siguientes pasos:</p>
                <p class="mb-4">1. Ve al perfil de la persona que has bloqueado.</p>
                <p class="mb-4">2. Pulsa en '<ion-icon name="ellipsis-vertical"></ion-icon>'.</p>
                  <p class="mb-4">3. En el desplegable pulsa sobre '<ion-icon name="ban-outline"></ion-icon>Desbloquear usuario'</span>.</p>`
    }
  ];

  const faqRef = useRef(null);
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    const faqElements = faqRef.current.querySelectorAll(".faq-item");

    gsap.from(faqElements, {
      opacity: 0,
      y: 50,
      stagger: 0.2,
      duration: 1.3,
      delay: 0.5,
      ease: "power2.out",
      scrollTrigger: {
        trigger: faqRef.current,
        start: "top 85%",
        end: "bottom 15%",
      },
    });
  }, []);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-screen-xl mx-auto py-28" ref={faqRef}>
      <MaskText
        text={["¿Tienes dudas? Tenemos respuestas."]}
        textSize="text-4xl/tight"
        responsiveTextSize="text-5xl/tight"
      />
      <div className="space-y-0 mt-12 max-lg:mx-10">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item border-b">
            {/* Pregunta */}
            <div
              onClick={() => toggleFAQ(index)}
              className="w-full py-6 cursor-pointer text-left flex justify-between text-2xl/tight items-center text-xl font-semibold text-gray-800"
            >
              {faq.question}
              <span className="ml-2">{openIndex === index ? "–" : "+"}</span>
            </div>

            {/* Respuesta */}
            <div
              className={`faq-answer overflow-hidden transition-[max-height] duration-500 ease-in-out`}
              style={{
                maxHeight: openIndex === index ? "500px" : "0",
              }}
            >
              <div
                className="text-md text-gray-600 leading-relaxed px-0 pb-5"
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
