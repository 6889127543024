import React from 'react';
import '../App.css';
import LogoFooter from './layouts/LogoFooter';

const TermsOfUse = () => {
  return (
    <LogoFooter>
    <div className="container-ext px-5 pb-20 lg:px-32 lg:pb-40">
      <h1 className='lg:text-4xl text-5xl max-sm:text-center mt-5 max-sm:mb-3 mb-2'>Términos de uso</h1>
      <div className='content'>
      <h3 className='font-semibold text-sm mb-7 max-sm:text-center text-[#777]'>Última actualización: 22 de octubre de 2024</h3>

      <p>¡Te damos la bienvenida a Scala!</p>
<p>En Scala, nos apasiona ayudarte a alcanzar tus metas y sueños. Queremos que te sientas parte de una comunidad donde puedas compartir tus logros y motivar a otros a hacer lo mismo.
Para garantizar una experiencia positiva para todos, hemos creado estos Términos y Condiciones, junto con nuestra Política de Privacidad. Estamos aquí para ayudarte en cada paso que des con tus objetivos.
Si tienes alguna pregunta o inquietud, no dudes en ponerte en contacto con nosotros. ¡Estamos aquí para ayudarte a lograr tus objetivos en Scala! </p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>1. Acuerdo de términos.</h3>
<p>Al utilizar nuestros Servicios, usted acepta estar sujeto a estos Términos. Si no acepta estar sujeto a estos Términos, no utilice los Servicios. “Servicios”, implica todos los aspectos de las aplicaciones móviles de Scala y nuestro sitio web (https://scala-app.com/), así como el software y los sistemas que alimentan las aplicaciones y nuestro sitio web.</p>
<h3 className='font-semibold text-2xl mt-7 mb-4'>2. Política de Privacidad.</h3>
<p>Revise nuestra Pol&iacute;tica de privacidad, que tambi&eacute;n rige su uso de los Servicios, para obtener informaci&oacute;n sobre c&oacute;mo recopilamos, usamos y compartimos su informaci&oacute;n.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>3. Cambios en estos T&eacute;rminos o en los Servicios.</h3>
   <p>En Scala, nos esforzamos por mejorar continuamente nuestros servicios para brindarte la mejor experiencia posible. Por esta raz&oacute;n, podemos realizar actualizaciones peri&oacute;dicas en estos T&eacute;rminos, las cuales te notificaremos mediante publicaciones en el sitio web, la aplicaci&oacute;n y/o a trav&eacute;s de otras comunicaciones. Te recomendamos que revises estos T&eacute;rminos cada vez que haya una actualizaci&oacute;n o utilices nuestros servicios. Si decides continuar utilizando Scala despu&eacute;s de que hayamos publicado los T&eacute;rminos actualizados, entenderemos que aceptas los cambios realizados. Sin embargo, si no est&aacute;s de acuerdo con los cambios, lamentablemente no podr&aacute;s seguir utilizando nuestros servicios. Ten en cuenta que, debido a la naturaleza en evoluci&oacute;n de nuestros servicios, podemos realizar cambios o suspender parte o la totalidad de los mismos en cualquier momento y sin previo aviso, a nuestro criterio exclusivo.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>4. &iquest;Qui&eacute;n puede utilizar los servicios?</h3>
   <p>Para utilizar los servicios de Scala, debes tener al menos 13 a&ntilde;os de edad (o la edad m&iacute;nima requerida para dar tu consentimiento para el procesamiento de datos seg&uacute;n las leyes de tu pa&iacute;s). Adem&aacute;s, debes cumplir con todas las leyes aplicables que regulen el uso de nuestros servicios. Si eres menor de 13 a&ntilde;os pero mayor de la edad m&iacute;nima requerida en tu jurisdicci&oacute;n para utilizar nuestros servicios, debes contar con el consentimiento de tus padres o tutores legales. Al utilizar nuestros servicios, declaras y garantizas que cuentas con el consentimiento de tus padres o tutores legales, quienes han le&iacute;do y aceptado estos T&eacute;rminos en tu nombre. Los padres y tutores legales son responsables de supervisar el uso de los servicios por parte de los menores, independientemente de si han autorizado o no dicho uso.</p>

<p>Los servicios de Scala est&aacute;n dise&ntilde;ados para ser utilizados exclusivamente por personas reales. Los bots u otros programas automatizados no est&aacute;n autorizados a utilizar nuestros servicios. Al registrarte y utilizar Scala, confirmas que eres una persona real y no est&aacute;s utilizando nuestros servicios de forma automatizada o en representaci&oacute;n de un tercero. Nos reservamos el derecho de tomar medidas, incluida la suspensi&oacute;n o eliminaci&oacute;n de cuentas, si determinamos que se est&aacute; utilizando nuestra plataforma de manera indebida mediante el uso de bots u otros medios automatizados.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>5. Comentarios. </h3>
  <p>Valoramos enormemente tus comentarios, ideas, propuestas y sugerencias para mejorar Scala. Al enviarnos tus comentarios, aceptas que tenemos el derecho de utilizarlos libremente, sin restricciones ni compensaci&oacute;n para ti, con el objetivo de mejorar y desarrollar nuestros servicios. Apreciamos tu contribuci&oacute;n para hacer de Scala una mejor plataforma para todos nuestros usuarios.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>6. Tu Contenido.</h3>
<p> A) Publicaci&oacute;n de Contenido. En Scala, te ofrecemos la posibilidad de compartir una amplia variedad de contenido, que puede incluir texto e im&aacute;genes. Todo lo que decidas publicar, subir o compartir a trav&eacute;s de nuestros servicios se considerar&aacute; como tu &quot;Contenido de Usuario&quot;. Es importante que comprendas que Scala no reclama ning&uacute;n derecho de propiedad sobre tu Contenido de Usuario. Esto significa que conservas todos los derechos de propiedad sobre el contenido que compartes a trav&eacute;s de nuestra plataforma. Nada en estos t&eacute;rminos restringe tus derechos en cuanto a tu Contenido de Usuario. </p>
<p>B) Permisos para tu Contenido de Usuario. Al compartir tu Contenido de Usuario en Scala, nos concedes una licencia no exclusiva, transferible, mundial y libre de regal&iacute;as para utilizar, reproducir, modificar, adaptar, distribuir y mostrar p&uacute;blicamente tu contenido. Esta licencia nos permite utilizar tu contenido en relaci&oacute;n con la prestaci&oacute;n de nuestros servicios, incluyendo su promoci&oacute;n, operaci&oacute;n, y mejora. Ten en cuenta que esta licencia es necesaria para que podamos ofrecer nuestros servicios de manera efectiva y proporcionarte la mejor experiencia posible en Scala. </p>
<p>C) Tu Responsabilidad por el Contenido de Usuario. Es importante que comprendas que eres completamente responsable de todo el contenido que publiques o compartas a trav&eacute;s de Scala. Al subir tu Contenido de Usuario, declaras y garantizas que posees todos los derechos necesarios sobre dicho contenido y que su uso no infringe los derechos de terceros ni viola ninguna ley aplicable. Esto incluye, entre otros aspectos, garantizar que tienes todos los derechos de autor y permisos necesarios para utilizar y compartir cualquier imagen u otros materiales protegidos por derechos de autor. </p>
<p>D) Eliminaci&oacute;n de Contenido de Usuario. Tienes el derecho de eliminar tu Contenido de Usuario en cualquier momento, siempre que lo desees. Sin embargo, es importante que comprendas que la eliminaci&oacute;n de tu contenido puede no ser completamente efectiva, y es posible que algunas copias o versiones anteriores de tu contenido permanezcan en nuestros servidores o en la memoria cach&eacute; de otros usuarios. Por lo tanto, te recomendamos que tengas cuidado al compartir cualquier contenido sensible o privado a trav&eacute;s de Scala. </p>
<p>E) Propiedad Intelectual de Scala. En Scala, nos esforzamos por respetar los derechos de propiedad intelectual de terceros. Sin embargo, tambi&eacute;n conservamos todos los derechos sobre el contenido que ofrecemos a trav&eacute;s de nuestros servicios y que est&aacute; sujeto a derechos de propiedad intelectual. Esto incluye, entre otros, el dise&ntilde;o, la presentaci&oacute;n y la organizaci&oacute;n de nuestro sitio web y de cualquier contenido protegido por derechos de autor que podamos proporcionar a trav&eacute;s de nuestros servicios.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>7. Licencia y T&eacute;rminos de las Aplicaciones.</h3>

<p>A) Licencia de la Aplicaci&oacute;n. Al aceptar estos T&eacute;rminos, te concedemos una licencia limitada, no exclusiva e intransferible para descargar e instalar la Aplicaci&oacute;n en tus dispositivos personales, como computadoras, tel&eacute;fonos m&oacute;viles, tabletas y otros dispositivos compatibles. Puedes utilizar la Aplicaci&oacute;n &uacute;nicamente para tus propios fines personales y no comerciales. Queda prohibido: (i) copiar, modificar o crear obras derivadas basadas en la Aplicaci&oacute;n; (ii) distribuir, transferir, sublicenciar, arrendar, prestar o alquilar la Aplicaci&oacute;n a terceros; (iii) realizar ingenier&iacute;a inversa, descompilar o desensamblar la Aplicaci&oacute;n (salvo que lo permita la ley aplicable); o (iv) poner la funcionalidad de la Aplicaci&oacute;n a disposici&oacute;n de m&uacute;ltiples usuarios a trav&eacute;s de cualquier medio.</p>

<p>B) Informaci&oacute;n Adicional: Apple App Store. Si obtienes la Aplicaci&oacute;n en la App Store de Apple o la utilizas en un dispositivo iOS, ten en cuenta que Apple no tiene ninguna obligaci&oacute;n de proporcionar servicios de mantenimiento o soporte con respecto a la Aplicaci&oacute;n. Si la Aplicaci&oacute;n no cumple con cualquier garant&iacute;a aplicable, puedes notificar a Apple y recibir un reembolso por la compra de la Aplicaci&oacute;n, si corresponde. Apple no asume ninguna otra responsabilidad relacionada con la Aplicaci&oacute;n, incluyendo reclamaciones de terceros, incumplimiento de requisitos legales o reglamentarios, o infracci&oacute;n de derechos de propiedad intelectual. Al aceptar estos T&eacute;rminos, tambi&eacute;n declaras y garantizas que no est&aacute;s ubicado en un pa&iacute;s sujeto a un embargo del gobierno de los Estados Unidos ni en la lista de partes prohibidas o restringidas por el gobierno de los Estados Unidos, y que cumplir&aacute;s con los t&eacute;rminos de servicio de terceros aplicables al utilizar la Aplicaci&oacute;n.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>8. Restricciones Generales y Derechos de Aplicaci&oacute;n de Scala.</h3>

<p>A) Contenido de Usuario: Te comprometes a no compartir ning&uacute;n Contenido de Usuario que viole los derechos de propiedad intelectual de terceros, incluyendo patentes, derechos de autor, marcas comerciales, secretos comerciales u otros derechos de propiedad. Adem&aacute;s, no promover&aacute;s conductas que infrinjan la ley o den lugar a responsabilidad civil. El contenido fraudulento, difamatorio, obsceno, o que promueva la discriminaci&oacute;n, el odio o la violencia ser&aacute; inaceptable en nuestra plataforma.</p>

<p>B) Uso de Marca: No deber&aacute;s utilizar o modificar los Servicios de Scala, incluyendo marcas comerciales, logotipos o informaci&oacute;n exclusiva, sin nuestro consentimiento expreso por escrito.</p>

<p>C) Acceso no Autorizado: No intentar&aacute;s acceder a &aacute;reas no p&uacute;blicas de los Servicios, sistemas inform&aacute;ticos de Scala o sistemas t&eacute;cnicos de nuestros proveedores sin autorizaci&oacute;n.</p>

<p>D) Seguridad del Sistema: No intentar&aacute;s vulnerar la seguridad de los sistemas o redes de Scala, ni violar cualquier medida de seguridad o autenticaci&oacute;n establecida.</p>

<p>E) Evitar Medidas de Protecci&oacute;n: No intentar&aacute;s eludir o desactivar cualquier medida tecnol&oacute;gica implementada por Scala o nuestros proveedores para proteger los Servicios.</p>

<p>F) Uso de Herramientas Automatizadas: No utilizar&aacute;s herramientas automatizadas para acceder o extraer informaci&oacute;n de los Servicios, a menos que lo permitamos expresamente.</p>

<p>G) Uso de Metadatos: No usar&aacute;s metaetiquetas o metadatos que utilicen marcas registradas de Scala sin nuestro consentimiento por escrito.</p>

<p>H) Ingenier&iacute;a Inversa: No intentar&aacute;s descifrar, descompilar o desensamblar ning&uacute;n software utilizado para proporcionar los Servicios.</p>

<p>I) Privacidad de los Usuarios: No recopilar&aacute;s ni almacenar&aacute;s informaci&oacute;n de identificaci&oacute;n personal de otros usuarios sin su consentimiento expreso.</p>

<p>J) Suplantaci&oacute;n de Identidad: No te har&aacute;s pasar por otra persona ni tergiversar&aacute;s tu afiliaci&oacute;n con cualquier persona o entidad.</p>

<p>K) Cumplimiento Legal: Debes cumplir con todas las leyes y regulaciones aplicables al utilizar los Servicios de Scala.</p>

<p>L) Prohibici&oacute;n de Actividades: No alentar&aacute;s ni permitir&aacute;s que otros violen estas restricciones en nuestra plataforma.</p>

<p>Scala no tiene la obligaci&oacute;n de supervisar el contenido de los usuarios, pero nos reservamos el derecho de hacerlo para garantizar el cumplimiento de estas restricciones y de la ley. Adem&aacute;s, nos reservamos el derecho de eliminar o deshabilitar el acceso a cualquier contenido que consideremos inapropiado o que viole estas restricciones. Tambi&eacute;n cooperaremos con las autoridades legales en caso de violaciones de estas restricciones o actividades il&iacute;citas en nuestra plataforma.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>9. Enlaces a Sitios Web o Recursos de Terceros.</h3>
   <p>Los Servicios de Scala, incluida la Aplicaci&oacute;n, pueden ofrecerte acceso a sitios web de terceros u otros recursos. Este acceso se proporciona &uacute;nicamente para tu comodidad, y no asumimos responsabilidad por el contenido, productos o servicios disponibles en esos recursos o enlaces mostrados en dichos sitios web. Reconoces que eres el &uacute;nico responsable y asumes todos los riesgos asociados con tu uso de cualquier recurso de terceros.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>10. Finalizaci&oacute;n.</h3>
<p>Scala se reserva el derecho de suspender o cancelar tu acceso y uso de los Servicios, incluyendo la suspensi&oacute;n del acceso o la cancelaci&oacute;n de tu cuenta, a nuestra discreci&oacute;n, en cualquier momento y sin previo aviso. Puedes cancelar tu cuenta y eliminar su contenido en cualquier momento envi&aacute;ndonos un correo electr&oacute;nico a info@scala-app.com.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>11. Descargo de Responsabilidad de Garant&iacute;a.</h3>

<p>Los Servicios se proporcionan &quot;tal cual&quot;, sin garant&iacute;a de ning&uacute;n tipo. Renunciamos expresamente a cualquier garant&iacute;a impl&iacute;cita, incluyendo, entre otras, las garant&iacute;as de comerciabilidad, idoneidad para un prop&oacute;sito particular, disfrute tranquilo y no infracci&oacute;n. Adem&aacute;s, no garantizamos que los Servicios cumplan con tus requisitos o est&eacute;n disponibles de manera ininterrumpida, segura y libre de errores. Tampoco garantizamos la calidad, exactitud, puntualidad, veracidad, integridad o confiabilidad de cualquier informaci&oacute;n o contenido proporcionado a trav&eacute;s de los Servicios.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>12. Indemnizaci&oacute;n.</h3>

<p>Te comprometes a indemnizar y eximir de responsabilidad a Scala y a sus funcionarios, directores, empleados y agentes, de cualquier reclamo, disputa, demanda, responsabilidad, da&ntilde;o, p&eacute;rdida, costo y gasto, incluyendo honorarios legales y contables razonables, que surjan de tu acceso o uso de los Servicios, tu Contenido de Usuario, o tu violaci&oacute;n de estos T&eacute;rminos.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>13. Jurisdicci&oacute;n y Ley Aplicable </h3>
  <p>En la medida en que estas Condiciones te permitan iniciar un litigio en un tribunal, tanto t&uacute; como Scala acept&aacute;is la jurisdicci&oacute;n exclusiva de los tribunales espa&ntilde;oles y la ley espa&ntilde;ola. Ambas partes acuerdan que todas las reclamaciones y disputas (ya sean contractuales o de otro tipo), que surjan de las Condiciones o el Acuerdo de Usuario, o se relacionen con ellos, se litigar&aacute;n exclusivamente en los tribunales espa&ntilde;oles, y que dichas disputas se regir&aacute;n por la ley espa&ntilde;ola, a menos que esto est&eacute; prohibido por las leyes del pa&iacute;s donde resides.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>14. Disposiciones Generales.</h3>

<p>(A) Reserva de Derechos. Scala y sus licenciantes poseen exclusivamente todos los derechos, t&iacute;tulos e intereses sobre los Servicios, incluyendo todos los derechos de propiedad intelectual asociados. Reconoces que los Servicios est&aacute;n protegidos por derechos de autor, marcas registradas y otras leyes aplicables. Te comprometes a no eliminar, alterar ni ocultar ning&uacute;n derecho de autor, marca comercial, marca de servicio u otros avisos de derechos de propiedad incorporados o adjuntos a los Servicios.</p>

<p>(B) Acuerdo Completo. Estos T&eacute;rminos constituyen el entendimiento y acuerdo completo y exclusivo entre Scala y t&uacute; con respecto a los Servicios, reemplazando cualquier entendimiento previo, ya sea oral o escrito. Si alguna disposici&oacute;n de estos T&eacute;rminos se considera inv&aacute;lida o inaplicable por un tribunal competente, dicha disposici&oacute;n se aplicar&aacute; en la medida m&aacute;xima permitida, y las dem&aacute;s disposiciones permanecer&aacute;n vigentes y en pleno efecto. No podr&aacute;s ceder ni transferir estos T&eacute;rminos sin el consentimiento previo por escrito de Scala, excepto seg&uacute;n lo permita la ley aplicable en tu jurisdicci&oacute;n. Cualquier intento de asignaci&oacute;n o transferencia sin el consentimiento adecuado ser&aacute; nulo. Scala puede asignar o transferir libremente estos T&eacute;rminos sin restricciones. Estos T&eacute;rminos vincular&aacute;n y redundar&aacute;n en beneficio de las partes, sus sucesores y cesionarios permitidos.</p>

<p>(C) Avisos. Cualquier aviso u otra comunicaci&oacute;n proporcionada por Scala en virtud de estos T&eacute;rminos se enviar&aacute;: (i) por correo electr&oacute;nico; o (ii) mediante publicaci&oacute;n en los Servicios. La fecha de recepci&oacute;n se considerar&aacute; la fecha en que se transmita la notificaci&oacute;n por correo electr&oacute;nico.</p>

<p>(D) Renuncia de Derechos. El hecho de que Scala no haga cumplir cualquier derecho o disposici&oacute;n de estos T&eacute;rminos no se considerar&aacute; una renuncia a dicho derecho o disposici&oacute;n. La renuncia ser&aacute; efectiva solo si se realiza por escrito y firmada por un representante debidamente autorizado de Scala. El ejercicio de cualquier recurso bajo estos T&eacute;rminos no perjudicar&aacute; otros recursos disponibles para las partes.</p>

<h3 className='font-semibold text-2xl mt-7 mb-4'>15. Informaci&oacute;n de Contacto.</h3> 
<p>Si tienes alguna pregunta sobre estos T&eacute;rminos o los Servicios, no dudes en contactar con Scala en info@scala-app.com.</p>
</div>
    </div>
    </LogoFooter>
  );
}

export default TermsOfUse;
