import React, { useState, useEffect } from "react";
import "./SmartAppBanner.css";
import app_icon from "../../assets/images/app_icon.png";

const SmartAppBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [bannerHeight, setBannerHeight] = useState(0);

  // Actualizar altura del banner y aplicar estilos dinámicos al logo
  useEffect(() => {
    const banner = document.querySelector(".smart-app-banner");
    const logo = document.querySelector("#hero-logo-sc");

    if (banner) {
      const height = banner.offsetHeight;
      setBannerHeight(height);

      // Ajustar el logo si el banner está visible
      if (logo) {
        logo.style.top = `${height + 50}px`; // Altura del banner más 5px
      }
    }

    // Limpiar estilos del logo al desmontar
    return () => {
      if (logo) {
        logo.style.top = ""; // Restaurar estilos originales
      }
    };
  }, [isVisible]);

  // Ocultar el banner al hacer clic en cerrar
  const handleClose = () => {
    setIsVisible(false);
  };

  // Detectar si el dispositivo es móvil
  const isMobile = /Android/i.test(navigator.userAgent);

  // Si no es móvil o el banner está cerrado, no se muestra
  if (!isMobile || !isVisible) return null;

  return (
    <div className="smart-app-banner fixed top-0 left-0 right-0 w-full z-50 bg-[#F2F1F6] text-[#333] flex items-center justify-between px-4 py-3 shadow-xl">
      <div className="banner-content flex items-center">
        <div
          onClick={handleClose}
          className="close-button font-light me-5 text-[#666] text-xl cursor-pointer"
        >
          &times;
        </div>
        <img
          src={app_icon}
          alt="App Logo"
          className="w-12 h-12 mr-3 rounded"
        />
        <div>
          <div className="app-name text-[#222] font-bold text-lg leading-tight">
            Scala
          </div>
          <div className="app-description text-[#222] text-sm leading-tight">
            Comparte tus metas
          </div>
        </div>
      </div>
      <div className="banner-actions flex items-center">
        <div
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.scala.app",
              "_blank"
            )
          }
          className="cursor-pointer bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-300 flex items-center justify-center text-sm font-medium px-4 py-2 rounded-[80px]"
        >
          Descargar
        </div>
      </div>
    </div>
  );
};

export default SmartAppBanner;
